import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p">{`*`}{`Today will be our free Christmas Eve WOD, The 12 Days of Christmas,
at 10:00am at The Ville. All other classes are cancelled at both
locations. East will be open from 8:30-1:30 for open gym. We have a
beginners optional wod as well so bring a friend!`}</strong></p>
    <p><em parentName="p">{`12-Day of Christmas`}</em></p>
    <p>{`1-SDHP (75/55)`}</p>
    <p>{`2-Thrusters`}</p>
    <p>{`3-Push Press`}</p>
    <p>{`4-Power Clean`}</p>
    <p>{`5-Power Snatch`}</p>
    <p>{`6-KBS (53/35)`}</p>
    <p>{`7-Pullups`}</p>
    <p>{`8-K2E’s`}</p>
    <p>{`9-Box Jumps (24/20)`}</p>
    <p>{`10-Double Unders`}</p>
    <p>{`11-Burpees`}</p>
    <p>{`12-OH Walking Lunges (25/15)`}</p>
    <p><em parentName="p">{`*`}{`Performed like the song.  1, 2-1, 3-2-1, 4-3-2-1, etc.`}</em></p>
    <p><em parentName="p">{`*`}{`*`}{`RX+ option available as well!`}</em></p>
    <p><em parentName="p">{`Beginners 12 Days of Christmas WOD`}</em></p>
    <p>{`1-Stone Clean`}</p>
    <p>{`2-Jump Squats`}</p>
    <p>{`3-Lunges/leg`}</p>
    <p>{`4-Back Extensions`}</p>
    <p>{`5-Ring Rows`}</p>
    <p>{`6-Burpees`}</p>
    <p>{`7-Wall Balls`}</p>
    <p>{`8-V Ups`}</p>
    <p>{`9-Push Ups`}</p>
    <p>{`10-Sit Ups`}</p>
    <p>{`11-KBS’s`}</p>
    <p>{`12-DB Push Press`}</p>
    <p><em parentName="p">{`*`}{`All weights will be scaled as needed for each participant`}</em></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`New Ville shirts and tanks have arrived!  Check them out next time
you’re in.`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Our 2nd Annual Pre Open in house competition will start January 1st
so get ready! RX Men, RX Women, scaled, and masters (50+) divisions.
More details to come.`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`The next Strength Program will start January 7th. For more
information email Eric at: Fallscitystrength\\@gmail.com.`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Saturday, February 4th, we will have our 3rd Annual Sports
Nutrition seminar will Dawn from Sports Nutrition 2 Go in Cincinnati.
Metabolic Testing will be offered before and after the seminar as
well!`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      